/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: signupByInviteLink
// ====================================================

export interface signupByInviteLink_signupByInviteLink_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  /**
   * last name of the person
   */
  last_name: string | null;
  id: number;
  /**
   * phone number of the person
   */
  phone_number: string;
  verified: boolean;
  email: any | null;
}

export interface signupByInviteLink_signupByInviteLink {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_token: string;
  refresh_expiry: any | null;
  user: signupByInviteLink_signupByInviteLink_user | null;
}

export interface signupByInviteLink {
  signupByInviteLink: signupByInviteLink_signupByInviteLink | null;
}

export interface signupByInviteLinkVariables {
  eventId: number;
  inviteToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMessage
// ====================================================

export interface createMessage {
  createMessage: string;
}

export interface createMessageVariables {
  eventId: number;
  content: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRsvpStatus
// ====================================================

export interface updateRsvpStatus {
  updateRsvpStatus: boolean;
}

export interface updateRsvpStatusVariables {
  eventId: number;
  status: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payForCashGiftV1
// ====================================================

export interface payForCashGiftV1 {
  /**
   * called when payment for cash gift is successful
   */
  payForCashGiftV1: boolean;
}

export interface payForCashGiftV1Variables {
  payload: payForCashGiftRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payForWishListItemV1
// ====================================================

export interface payForWishListItemV1 {
  /**
   * called when payment for a wish list item is successful
   */
  payForWishListItemV1: boolean;
}

export interface payForWishListItemV1Variables {
  payload: payForWishlistItemRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: payForSprayMoneyV1
// ====================================================

export interface payForSprayMoneyV1 {
  /**
   * called when payment for spray money is successful
   */
  payForSprayMoneyV1: boolean;
}

export interface payForSprayMoneyV1Variables {
  payload: payForSprayMoneyRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getHeroImage
// ====================================================

export interface getHeroImage {
  getHeroImage: string;
}

export interface getHeroImageVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEventStreamers
// ====================================================

export interface getEventStreamers_getEventStreamers_event_event_preference {
  __typename: "EventPreference";
  live_hall: boolean | null;
  private: boolean | null;
}

export interface getEventStreamers_getEventStreamers_event {
  __typename: "Event";
  event_preference: getEventStreamers_getEventStreamers_event_event_preference | null;
}

export interface getEventStreamers_getEventStreamers {
  __typename: "Streamer";
  Id: string;
  is_streaming: boolean;
  playback_url: string;
  deviceName: string;
  viewingUrl: string;
  event: getEventStreamers_getEventStreamers_event;
}

export interface getEventStreamers {
  /**
   * STREAMERS
   */
  getEventStreamers: (getEventStreamers_getEventStreamers | null)[] | null;
}

export interface getEventStreamersVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventBySlug
// ====================================================

export interface eventBySlug_eventBySlug_event_wallet {
  __typename: "Wallet";
  balance: number;
  currency: Currency;
  /**
   * the uuid that identifies the wallet
   */
  id: string;
  /**
   * is true if the wallet has been frozen
   */
  frozen: boolean;
}

export interface eventBySlug_eventBySlug {
  __typename: "Event";
  welcome_msg: string | null;
  slug: string | null;
  id: number;
  uuid: string;
  name: string;
  hash_tag: string | null;
  template_id: string;
  date: string | null;
  event_wallet: (eventBySlug_eventBySlug_event_wallet | null)[] | null;
}

export interface eventBySlug {
  eventBySlug: eventBySlug_eventBySlug | null;
}

export interface eventBySlugVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventParts
// ====================================================

export interface eventParts_eventParts {
  __typename: "EventPart";
  id: number;
  name: string;
  part: string;
  time: any;
  city: string | null;
  address: string;
  map_coordinate: string | null;
  geo_position: {
    geo_position_id: string;
    latitude: string;
    longitude: string;
  };
}

export interface eventParts {
  eventParts: eventParts_eventParts[];
}

export interface eventPartsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: refresh
// ====================================================

export interface refresh_refresh_user {
  __typename: "User";
  /**
   * first name of the person
   */
  first_name: string;
  id: number;
  email: any | null;
  /**
   * last name of the person
   */
  last_name: string | null;
  /**
   * phone number of the person
   */
  phone_number: string;
  verified: boolean;
}

export interface refresh_refresh {
  __typename: "AuthResponse";
  access_expiry: any | null;
  access_token: string;
  refresh_expiry: any | null;
  refresh_token: string;
  user: refresh_refresh_user | null;
}

export interface refresh {
  /**
   * deprecated
   */
  refresh: refresh_refresh;
}

export interface refreshVariables {
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBackStory
// ====================================================

export interface getBackStory_getBackStory {
  __typename: "BackStory";
  id: number;
  role: BackStoryRole;
  story: string;
  title: string | null;
  image: string | null;
  event_id: number;
}

export interface getBackStory {
  getBackStory: (getBackStory_getBackStory | null)[] | null;
}

export interface getBackStoryVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: invitationCard
// ====================================================

export interface invitationCard_invitationCard {
  __typename: "InvitationCard";
  template: string | null;
  id: number | null;
}

export interface invitationCard {
  invitationCard: invitationCard_invitationCard | null;
}

export interface invitationCardVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAlbumDetails
// ====================================================

export interface getAlbumDetails_getMedia_meta {
  __typename: "MediaMeta";
  s3_url: string | null;
  height: number | null;
  width: number | null;
}

export interface getAlbumDetails_getMedia {
  __typename: "AlbumMedia";
  id: string;
  url: string | null;
  meta: getAlbumDetails_getMedia_meta | null;
}

export interface getAlbumDetails {
  getMedia: (getAlbumDetails_getMedia | null)[] | null;
}

export interface getAlbumDetailsVariables {
  eventUuid: string;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWishListItems
// ====================================================

export interface getWishListItems_getWishListItems {
  __typename: "WishListItem";
  id: number;
  uuid: any | null;
  name: string | null;
  image: string | null;
  paid: boolean | null;
  amount: number | null;
  currency: string | null;
  qty: number | null;
}

export interface getWishListItems {
  getWishListItems: (getWishListItems_getWishListItems | null)[] | null;
}

export interface getWishListItemsVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventPreferences
// ====================================================

export interface eventPreferences_event_event_preference {
  __typename: "EventPreference";
  id: number;
  food: boolean | null;
  ads: boolean | null;
  asoebi_selection: boolean | null;
  gifts: boolean | null;
  store: boolean | null;
  vendor_store: boolean | null;
  reservation: boolean | null;
  dress_code: boolean | null;
  private: boolean | null;
  live_hall: boolean | null;
}

export interface eventPreferences_event {
  __typename: "Event";
  event_preference: eventPreferences_event_event_preference | null;
}

export interface eventPreferences {
  event: eventPreferences_event | null;
}

export interface eventPreferencesVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getApprovedMessages
// ====================================================

export interface getApprovedMessages_getApprovedMessages {
  __typename: "Message";
  id: number;
  content: string | null;
  status: boolean;
}

export interface getApprovedMessages {
  getApprovedMessages:
    | (getApprovedMessages_getApprovedMessages | null)[]
    | null;
}

export interface getApprovedMessagesVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: eventVendor
// ====================================================

export interface eventVendor_eventVendor {
  __typename: "EventVendor";
  id: number;
  name: string | null;
  instagram_handle: string | null;
  phone_number: string;
  role: string | null;
  email: string | null;
}

export interface eventVendor {
  eventVendor: (eventVendor_eventVendor | null)[];
}

export interface eventVendorVariables {
  eventId: number;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getForeignAccountDetails
// ====================================================

export interface getForeignAccountDetails_getForeignAccountDetails {
  __typename: "AccountDetails";
  event_id: number;
  walletId: string;
  account_number: string;
  bank_name: string;
  currency: Currency;
  beneficiary_name: string;
  swift_code: string;
  country: string;
  city: string;
  bank_address: string | null;
  post_code: string;
  sort_code: string;
}

export interface getForeignAccountDetails {
  getForeignAccountDetails: (getForeignAccountDetails_getForeignAccountDetails | null)[];
}

export interface getForeignAccountDetailsVariables {
  eventId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLiveSchedule
// ====================================================

export interface getLiveSchedule_getLiveSchedule_data_event_part {
  __typename: "EventPart";
  id: number;
  name: string;
  uuid: string;
}

export interface getLiveSchedule_getLiveSchedule_data {
  __typename: "LiveSchedule";
  id: any;
  event_uuid: any;
  event_part_uuid: any;
  link: string;
  time: any;
  created_at: any | null;
  updated_at: any | null;
  event_part: getLiveSchedule_getLiveSchedule_data_event_part;
}

export interface getLiveSchedule_getLiveSchedule {
  __typename: "PaginatedLiveSchedule";
  page: number;
  pageSize: number;
  pageCount: number;
  recordCount: number;
  data: getLiveSchedule_getLiveSchedule_data[];
}

export interface getLiveSchedule {
  getLiveSchedule: getLiveSchedule_getLiveSchedule;
}

export interface getLiveScheduleVariables {
  eventUuid: string;
  page?: number | null;
  pageSize?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BackStoryRole {
  Bride = "Bride",
  Groom = "Groom",
}

export enum Currency {
  gpb = "gpb",
  ngn = "ngn",
  usd = "usd",
}

export enum paymentProcessor {
  flutterwave = "flutterwave",
  monnify = "monnify",
  paystack = "paystack",
  stripe = "stripe",
}

export interface payForCashGiftRequest {
  reference: string;
  currency: Currency;
  eventID: number;
  amount?: number | null;
  status?: boolean | null;
  fullName?: string | null;
  phone?: string | null;
  email?: string | null;
  creditorAlias?: string | null;
  paymentProcessor: paymentProcessor;
}

export interface payForSprayMoneyRequest {
  reference: string;
  currency: Currency;
  event_id: number;
  amount: number;
  units: string;
  status: boolean;
  full_name: string;
  phone?: any | null;
  email: any;
  paymentProcessor: paymentProcessor;
}

export interface payForWishlistItemRequest {
  currency: Currency;
  itemId: string;
  itemName: string;
  reference: string;
  event_id: number;
  amount: number;
  status: boolean;
  full_name: string;
  phone?: any | null;
  email: any;
  creditorAlias?: string | null;
  paymentProcessor: paymentProcessor;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
